<template>
  <div class="base-content">
    <query-frame
      :current="page.current"
      :total="page.total"
      @on-query="handleQuery"
      @on-reset="handleReset"
      :add-visible="false"
      @on-page-change="handlePageChange"
      @on-size-change="handleSizeChange"
    >
      <!-- 查询条件开始 -->
      <template slot="query">
        <!--<query-item label="门店名称">-->
          <!--<a-input v-model="query.storeName" placeholder="门店名称"></a-input>-->
        <!--</query-item>-->
        <query-item label="门店名称" v-if="isStoreUser">
<!--          <store-list-select v-model="query.shopId"></store-list-select>-->
          <a-select
            allowClear
            mode="multiple"
            placeholder="请选择"
            v-model="query.shopIds">
            <a-select-option
              v-for="(item) in storeList"
              :key="item.shopId"
              :value="item.shopId">
              {{item.shopName}}
            </a-select-option>
          </a-select>
        </query-item>
        <query-item label="查询时间">
          <i-date-picker v-model="query.duration"></i-date-picker>
        </query-item>
        <query-item label="订单类型">
          <a-select v-model="query.orderType" placeholder="请选择">
            <a-select-option :value="1">
              商品订单
            </a-select-option>
            <a-select-option :value="10">
              充值订单
            </a-select-option>
          </a-select>
        </query-item>
        <query-item label="支付方式">
          <a-select v-model="query.payMethod" placeholder="请选择">
            <a-select-option :value="item.value" v-for="item in payList" :key="item.value">
              {{item.label}}
            </a-select-option>
          </a-select>
        </query-item>
        <query-item label="订单状态">
          <a-select v-model="query.orderStatus" allowClear placeholder="请选择">
            <a-select-option :value="8">
              已退款
            </a-select-option>
          </a-select>
        </query-item>

      </template>
      <!-- 查询条件结束 -->

      <div class="query__summary" slot="summary">
        <a-button class="mr-10" type="primary" icon="download" @click="reportSerialsExport">导出</a-button>
        <div class="query__summary__title">合计</div>
        <!--<div class="query__summary__group">-->
          <!--<div class="query__summary&#45;&#45;label">收入：</div>-->
          <!--<div class="query__summary&#45;&#45;value">888 元</div>-->
        <!--</div>-->
        <!--<div class="query__summary__group">-->
          <!--<div class="query__summary&#45;&#45;label">退款：</div>-->
          <!--<div class="query__summary&#45;&#45;value">0 元</div>-->
        <!--</div>-->
        <!--<div class="query__summary__group">-->
          <!--<div class="query__summary&#45;&#45;label">充值：</div>-->
          <!--<div class="query__summary&#45;&#45;value">368 元</div>-->
        <!--</div>-->
        <!--<div class="query__summary__group">-->
          <!--<div class="query__summary&#45;&#45;label">订单：</div>-->
          <!--<div class="query__summary&#45;&#45;value">30 个</div>-->
        <!--</div>-->
        <div class="query__summary__group primary-color">
          <div class="query__summary--label">净收入：</div>
          <div class="query__summary--value">{{total.amountPaidGroup}} RM</div>
        </div>
      </div>
      <!-- 查询结果 开始 -->
      <template slot="table">
        <i-table :columns="columns" :dataSource="dataList">
        </i-table>
      </template>
      <!-- 查询结果 结束 -->
    </query-frame>
  </div>
</template>

<script>
import moment from 'moment';
import { reportSerials, reportSerialsExport } from '@/api/finance';
import queryMixin from '@/mixin/queryMixin';
import IDatePicker from '../../components/IDatePicker.vue';
import { getOwnShopList } from '@/api/store';

export default {
  name: 'FinanceList',
  mixins: [queryMixin],
  components: {
    IDatePicker
  },
  props: {},
  data() {
    const startDate = moment().subtract(6, 'days').format('YYYY-MM-DD');
    const endDate = moment(new Date()).format('YYYY-MM-DD');
    return {
      storeList: [],
      query: {
        shopIds: [],
        shopId: '',
        payMethod: '',
        duration: [startDate, endDate],
        type: undefined,
      },
      total: {},
      // 默认展示表格
      columns: [
        {
          title: '序号',
          ellipsis: true,
          width: 60,
          customRender(text, item, index) {
            return index + 1;
          }
        },
        {
          title: '门店名称',
          dataIndex: 'shopName',
          key: 'shopName',
          ellipsis: true,
          width: 200,
        },
        {
          title: '单据编号',
          dataIndex: 'orderId',
          key: 'orderId',
          ellipsis: true,
          width: 180,
        },
        {
          title: '单据类型',
          dataIndex: 'orderTypeName',
          key: 'orderTypeName',
          ellipsis: true,
          width: 120,
        },
        {
          title: '新开卡',
          dataIndex: 'newMemberFlag',
          key: 'newMemberFlag',
          ellipsis: true,
          width: 120,
        },
        {
          title: '商品名称',
          dataIndex: 'goodsName',
          key: 'goodsName',
          ellipsis: true,
          width: 120,
        },
        {
          title: '商品数量',
          dataIndex: 'itemCount',
          key: 'itemCount',
          ellipsis: true,
          width: 120,
        },
        {
          title: '单据金额',
          dataIndex: 'amountPay',
          key: 'amountPay',
          ellipsis: true,
          width: 120,
        },
        {
          title: '优惠金额',
          dataIndex: 'amountDiscount',
          key: 'amountDiscount',
          ellipsis: true,
          width: 120,
        },
        {
          title: '实付金额',
          dataIndex: 'amountPaid',
          key: 'amountPaid',
          ellipsis: true,
          width: 120,
        },
        {
          title: '退款金额',
          dataIndex: 'amountRefund',
          key: 'amountRefund',
          ellipsis: true,
          width: 120,
        },
        {
          title: '充值劵名称',
          dataIndex: 'couponName',
          key: 'couponName',
          ellipsis: true,
          width: 120,
        },
        {
          title: '支付方式',
          dataIndex: 'payMethodName',
          key: 'payMethodName',
          ellipsis: true,
          width: 120,
        },
        {
          title: '会员手机号',
          dataIndex: 'mobile',
          key: 'mobile',
          ellipsis: true,
          width: 130,
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key: 'createTime',
          ellipsis: true,
          width: 180,
        },
        {
          title: '开单备注',
          dataIndex: 'discountWayRamarks',
          key: 'discountWayRamarks',
          ellipsis: true,
          width: 180,
        },
        {
          title: '充值备注',
          dataIndex: 'remarks',
          key: 'remarks',
          ellipsis: true,
          width: 180,
        },
        {
          title: '操作人',
          dataIndex: 'createBy',
          key: 'createBy',
          ellipsis: true,
          width: 180,
        },
        {
          title: '订单来源',
          dataIndex: 'orderSource',
          key: 'orderSource',
          ellipsis: true,
          width: 180,
        },
        {
          title: '车牌号',
          dataIndex: 'carNumber',
          key: 'carNumber',
          ellipsis: true,
          width: 180,
        },
        {
          title: '车主姓名',
          dataIndex: 'ownerName',
          key: 'ownerName',
          ellipsis: true,
          width: 180,
        },
        {
          title: '车主手机号',
          dataIndex: 'carMobile',
          key: 'carMobile',
          ellipsis: true,
          width: 180,
        },
        {
          title: '注册登记日期',
          dataIndex: 'registerTime',
          key: 'registerTime',
          ellipsis: true,
          width: 180,
        },
        {
          title: '保险到期日期',
          dataIndex: 'insuranceExpirationTime',
          key: 'insuranceExpirationTime',
          ellipsis: true,
          width: 180,
        },
        {
          title: '单位介绍人',
          dataIndex: 'introducer',
          key: 'introducer',
          ellipsis: true,
          width: 180,
        },
        // {
        //   title: '状态',
        //   dataIndex: 'status',
        //   key: 'status',
        //   width: 120,
        // },
        // {
        //   title: '操作',
        //   dataIndex: 'action',
        //   key: 'action',
        //   fixed: 'right',
        //   width: 220,
        //   scopedSlots: { customRender: 'action' },
        //   customRender: (text, row) => {
        //     return {
        //       children: <section>
        //         <a-button type="link" size="small" onClick={ () => this.handleInfo(row) }>查看</a-button>
        //       </section>,
        //     }
        //   }
        // },
      ],
      dataList: [],
      payList: [
        {
          label: '现金',
          value: 0
        },
        {
          label: '会员卡',
          value: 3
        },
        {
          label: '惠商-聚合支付',
          value: 6
        },
      ]
    };
  },
  computed: {
    isStoreUser() {
      const userInfo = this.$store.getters.getUserInfo;
      return userInfo.userDTO.shopId === 0;
    }
  },
  watch: {},
  created() {
    this.getOwnStoreList();
    this.handleQuery();

  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    getOwnStoreList() {
      getOwnShopList().then((data) => {
        this.storeList = data;
      });
    },
    // 获取列表
    getData() {
      this.params.pageIndex = this.page.current;
      this.params.pageSize = this.page.pageSize;

      if (this.params.duration && this.params.duration.length) {
        this.params.startDate = this.params.duration[0];
        this.params.endDate = this.params.duration[1];
        delete this.params.duration;
      }
      // console.log(this.params)
      reportSerials(this.params).then((data) => {
        const { totalCount, list, ext } = data;
        this.page.total = totalCount;
        if(this.query.orderStatus === 8){
          list.forEach((item) => {
            item.discountWayRamarks = "已退款"
          });
        }
        this.dataList = list;
        // console.log(this.dataList)
        this.total = ext;
      });
    },
    reportSerialsExport() {
      if (this.query.duration && this.query.duration.length) {
        this.params.startDate = this.query.duration[0];
        this.params.endDate = this.query.duration[1];
      }
      if(this.query.shopIds && this.query.shopIds.length){
        this.params.shopIds = this.query.shopIds;
      }
      // console.log(this.params)
      reportSerialsExport(this.params).then((data) => {
        window.open(data);
        // window.location.href = data;
      });
    },
    handleInfo(row) {
      // this.$router.push({
      //   name: 'chargeRuleInfo',
      //   params: {
      //     id: row.id
      //   }
      // });
    },
  }
};
</script>

<style lang="less" scoped>
  .query__summary {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 16px;
    .query__summary__title {
      margin-right: 16px;
      font-weight: bold;
    }
    .query__summary__group {
      display: flex;
      margin-right: 16px;
      .query__summary--value {
        min-width: 20px;
      }
    }
  }
</style>
